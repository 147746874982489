import appleIcon from '@/assets/images/apple.svg';
import ballIcon from '@/assets/images/ball.svg';
import bookIcon from '@/assets/images/book.svg';
import hatIcon from '@/assets/images/hat.svg';
import lightbulbIcon from '@/assets/images/lightbulb.svg';
import mathIcon from '@/assets/images/math.svg';
import paintIcon from '@/assets/images/paint.svg';
import paperIcon from '@/assets/images/paper.svg';
import paperCheckIcon from '@/assets/images/paper-check.svg';
import penPaperIcon from '@/assets/images/pen-paper.svg';
import pensIcon from '@/assets/images/pens.svg';
import physicsIcon from '@/assets/images/physics.svg';
import testIcon from '@/assets/images/test.svg';
import vialsIcon from '@/assets/images/vials.svg';

export default [
    { component: appleIcon, value: 'apple' },
    { component: ballIcon, value: 'ball' },
    { component: bookIcon, value: 'book' },
    { component: hatIcon, value: 'hat' },
    { component: lightbulbIcon, value: 'lightbulb' },
    { component: paperIcon, value: 'paper' },
    { component: paperCheckIcon, value: 'paper-check' },
    { component: penPaperIcon, value: 'pen-paper' },
    { component: pensIcon, value: 'pens' },
    { component: physicsIcon, value: 'physics' },
    { component: testIcon, value: 'test' },
    { component: vialsIcon, value: 'vials' },
    { component: paintIcon, value: 'paint' },
    { component: mathIcon, value: 'math' }
];
